@import '~antd/dist/antd.less';
@import '../style/theme.less';
@import '../style/index.less';

@font-face {
  font-family: 'AvenirLight';
  src: url('../../assets/font/regular/Avenir-regular.eot'); /* IE9 Compat Modes */
  src: url('../../assets/font/regular/Avenir-regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/font/regular/Avenir-regular.woff') format('woff'),
    url('../../assets/font/regular/Avenir-regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Avenir';
  src: url('../../assets/font/medium/Avenir-medium.eot');
  src: url('../../assets/font/medium/Avenir-medium.eot?#iefix') format('embedded-opentype'),
    url('../../assets/font/medium/Avenir-medium.woff') format('woff'),
    url('../../assets/font/medium/Avenir-medium.ttf') format('truetype');
}
@font-face {
  font-family: 'AvenirBold';
  src: url('../../assets/font/bold/AVENIR-bold.eot');
  src: url('../../assets/font/bold/AVENIR-bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/font/bold/AVENIR-bold.woff') format('woff'),
    url('../../assets/font/bold/AVENIR-bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Din';
  src: url('../../assets/font/din/DIN.eot');
  src: url('../../assets/font/din/DIN.eot?#iefix') format('embedded-opentype'),
    url('../../assets/font/din/DIN.woff') format('woff'),
    url('../../assets/font/din/DIN.ttf') format('truetype');
}
body {
  font-family: PingFangSC-Regular;
}
body::backdrop {
  background-color: #10131a;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
}
#root {
  height: 100%;
}
.pfRegular {
  font-family: PingFangSC-Regular;
}
.Din {
  font-family: Din;
}
.AvenirBold {
  font-family: AvenirBold;
}
.AvenirLight {
  font-family: AvenirLight;
}
.layout {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  .columnStartStretch;
  & > .manage-layout {
    .flex1;
    .startStart;
    overflow: hidden;
    .water-mark {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9999;
      pointer-events: none;
      background-size: 350px auto;
    }
    .manage-aside {
      height: 100%;
      overflow: auto;
      position: relative;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .manage-aside-collapsed {
        position: absolute;
        right: 16px;
        bottom: 16px;
        .pointer;
      }
      & > .ant-menu-inline {
        border-right: 0;
      }
    }
    .manage-layout-main {
      overflow: auto;
      height: 100%;
      padding: 16px;
      background-color: #f0f2f5;
      .flex1;
      .columnStartStretch;
      .manage-layout-main-card {
        .flex1;
        .startStretch;
        & > .ant-card-body {
          background-color: @white;
          min-width: 1200px;
          .flex1;
        }
      }
    }
  }
}
