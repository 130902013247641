@import '../style/theme.less';

.header {
  background-size: 48% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 66px;
  padding: 0 24px;

  .header-icon {
    width: 26px;
    height: 26px;
  }

  .header-icon-img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }

  .header-title {
    line-height: 100%;
    font-weight: var(--theme-header-title-font-weight);
    font-size: var(--theme-header-title-font-size);
    text-shadow: var(--theme-header-title-text-shadow);
  }

  .header-divider {
    width: 1px;
    height: 12px;
    background: @dark03;
  }

  .header-menu-selected {
    position: relative;
    color: @primary-color;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;
      left: 50%;
      width: 50%;
      height: 2px;
      transform: translateX(-50%);
      background: @primary-color;
      border-radius: 1.5px;
    }
  }

  .header-menu-selected-data-center {
    color: var(--theme-header-title-color);

    &::after {
      background: var(--theme-header-title-color);
    }
  }

  .header-from {
    .ant-form-item {
      margin-right: 12px;
    }
  }
}

.subway-custom-border {
  border: 1px solid rgba(75, 210, 222, 0.4) !important;
}

.custom-tree-select {
  .ant-select-selector {
    color: @white !important;
    background-color: transparent !important;
    // border: 0.5px solid #2d4a83 !important;
    border-radius: 8px !important;
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
  }

  .ant-select-arrow,
  .ant-select-selection-placeholder {
    // color: @white !important;
    color: rgba(255, 255, 255, 0.12) !important;
  }
}

.custom-tree-select-header {
  .ant-select-selector {
    border: 1px solid var(--theme-ant-select-selector-border-color) !important;
  }

  .ant-select-arrow,
  .ant-select-selection-placeholder {
    color: var(--theme-ant-select-selector-border-color) !important;
  }
}

.patrol-center-fence-search {
  .ant-select-selection-placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }

  .ant-select-selector {
    padding: 0 25px !important;

    input.ant-select-selection-search-input {
      padding: 0 16px !important;
    }
  }
}

.patrol-center-fence-search-dropdown {
  .ant-empty-description {
    color: #748399;
  }
}

.custom-treeSelect-dropdown.ant-tree-select-dropdown {
  width: 246px !important;
  // background: #424b5c;
  background: var(--theme-legend-mask-bg) !important;

  .ant-select-tree {
    background: transparent;
    color: @gray07;

    .ant-select-tree-node-content-wrapper:hover {
      background-color: rgba(245, 245, 245, 0.12) !important;
    }

    .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
      background-color: transparent !important;
      color: @white;
    }
  }

  .ant-select-tree-list-scrollbar-thumb {
    background-color: transparent !important;
  }
}

.custom-select {
  .ant-select-selector {
    color: @white;
    border-radius: 8px !important;
    background-color: transparent !important;
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
  }

  .ant-select-arrow,
  .ant-select-selection-placeholder {
    color: rgba(255, 255, 255, 0.12);
  }
}

.custom-select-header {
  .ant-select-selector {
    border: 1px solid var(--theme-ant-select-selector-border-color, rgba(255, 255, 255, 0.12)) !important;
  }

  .ant-select-arrow,
  .ant-select-selection-placeholder {
    color: var(--theme-ant-select-selector-border-color, rgba(255, 255, 255, 0.12));
  }
}

.custom-select-dropdown {
  padding: 0px;
  margin: 4px 0px;
  // background: #424b5c !important;
  background: var(--theme-legend-mask-bg) !important;

  .ant-select-item-option {
    background-color: transparent !important;
    color: @gray07;
  }

  .ant-select-item-option:hover {
    background-color: rgba(245, 245, 245, 0.12) !important;
  }

  .ant-select-item-option-selected {
    color: @white !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    background: transparent !important;
  }
}

.subway-custom-select {
  .ant-select-selector {
    border: 1px solid rgba(75, 210, 222, 0.4) !important;
  }

  .ant-select-arrow,
  .ant-select-selection-placeholder {
    color: rgba(75, 210, 222, 0.4);
  }
}

.subway-custom-select-dropdown {
  min-width: 0 !important;
  width: auto !important;
}

.dataCenterHeader {
  color: @white;
  // background-color: @dataCenterHeaderBg;
  background-color: var(--theme-header-bg);

  .header-divider {
    background: rgba(255, 255, 255, 0.12);
  }

  .ant-dropdown-trigger {
    color: @white;
  }
}

.header-search-select-icon {
  color: #fff;
  background-color: var(--theme-header-bg);
}
