.centerTop {
  .centerTopNum {
    // font-family: DIN;
    display: flex;
    font-family: AvenirbBold;
    font-size: 28px;
    font-weight: bold;
    line-height: 28px;
    letter-spacing: 0px;
  }
}
.typeface {
  font-family: Din;
  margin-right: 4px;
  color: #4bd2de;
  display: inline-block;
  padding: 6px 10px;
  height: 40px;
  border-radius: 4px;
  opacity: 1;
  background: linear-gradient(180deg, #384763 0%, #30405c 18%, #2a3a57 51%, #1e3b4d 100%);
  box-shadow: 0px 2px 6px 0px rgba(20, 30, 45, 0.51), inset 0px 0px 2px 0px rgba(255, 255, 255, 0.2);
}
.typeface-intercept-line {
  position: absolute;
  left: 0px;
  top: 20px;
  width: 100%;
  height: 2px;
  opacity: 1;
  background: #172235;
}
.centerShow {
  .centerSign-left {
    width: 48px;
    height: 1px;
    background: #4bd2de;
    position: absolute;
    top: -1px;
    left: 40px;
  }
  .centerSign-right {
    width: 48px;
    height: 1px;
    background: #4bd2de;
    position: absolute;
    top: -1px;
    right: 40px;
  }
  .centerSign-gradual {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 48px;
    border-radius: 6px;
    // opacity: 1;
    z-index: 1;
    background: linear-gradient(180deg, rgba(75, 210, 222, 0.22) -59%, rgba(75, 210, 222, 0) 100%);
  }
}
