@import '../../style/theme.less';

@labelColor: #748399;
@valueColor: rgba(255, 255, 255, 0.9);

.subway-center {
  padding: 12px;
  background-color: #10131a;
  background-image: radial-gradient(
    39% 54% at 50% 45%,
    rgba(78, 105, 141, 0.6) 0%,
    rgba(33, 47, 66, 0.4) 54%,
    rgba(33, 47, 66, 0.3) 70%,
    rgba(21, 23, 33, 0.5) 100%
  );
  color: @white;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-position: 20% 10%;
  background-repeat: no-repeat;
  background-size: cover;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .subway-center-adapt-box {
    min-height: calc(100vh - 66px - 24px);
  }
  .subway-center-left,
  .subway-center-right {
    width: 24%;
  }
  .subway-center-center {
    width: 52%;
    height: 100%;
  }
  .dataCenterMap {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
  }
  .indicator-name {
    color: @labelColor;
    line-height: 14px;
  }
  .indicator-value {
    font-size: 16px;
    line-height: 16px;
    margin-top: 6px;
    color: @valueColor;
    font-family: AvenirbBold;
  }
}
.hasbg {
  background-position: center center;
  // background-position: -100px 100px;
  background-repeat: no-repeat;
  background-size: 106% 100%;
  // -webkit-background-size: cover;
  // -o-background-size: cover;
  background-image: url('../../../assets/img/texture-bg.png');
}
.nobg {
  background-image: '';
  background: #10131a;
}
.custom-echart-tooltip {
  border-radius: 8px !important;
}
