.map-search-box {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 242px;
  padding: 12px 10px;
  background-color: #fafafa;
  .map-search-input {
    height: 32px;
    width: 100%;
  }
  .fenceMap-fenceSearch {
    .ant-collapse-header {
      padding: 12px 0 !important;
    }
    .ant-collapse-content-box {
      padding: 12px 0 !important;
      padding-top: 4px !important;
    }
  }
}
