.model {
  display: flex;
  .ant-modal {
    top: 0;
    padding-bottom: 0;
    margin: auto;
  }
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-close-icon {
    color: #767c89;
  }
  .ant-modal-body {
    padding: 32px;
  }
  .title {
    font-family: 'PingFang SC';
    font-weight: 600;
    font-size: 24px;
    color: #fff;
  }
  .tip {
    line-height: 44px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.1);
    margin: 24px 0;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  .content {
    display: flex;
    .box {
      width: 33.3%;
      padding: 24px;
      border-radius: 12px;
      background: #1a2737;
      .text {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.9);
        margin-bottom: 28px;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .ant-table {
    border-radius: 8px;
    overflow: hidden;
    background-color: transparent;
  }
  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: 0 1px 0 1px #424b5b;
  }
  .ant-table-cell-row-hover {
    background-color: #313d4a !important;
  }
  .ant-table-thead {
    background-color: transparent;
  }
  .ant-table-row {
    &:hover {
      td {
        background-color: #313d4a !important;
      }
    }
  }
  .ant-table-container {
    border-left: 1px solid #424b5b !important;
  }
  .ant-table-thead > tr > th {
    background-color: #545c6b;
    color: #dedede;
    border-color: #424b5b;
  }
  .ant-table-tbody > tr > td {
    background-color: #313d4a;
    color: #dedede;
    border-color: #424b5b;
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: #313d4a;
  }
  table {
    border-top: 1px solid #424b5b !important;
    tr > th,
    td {
      border-color: #424b5b !important;
      padding: 10px 12px;
    }
  }
  .ant-radio-group {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    .ant-radio-button-wrapper {
      background-color: transparent;
      border: none;
      color: #bccbe0;
      font-size: 14px;
      &:not(:first-child)::before {
        width: 0;
      }
    }
    .ant-radio-button-wrapper-checked {
      background-color: rgba(255, 255, 255, 0.2) !important;
      color: #ffffff;
      border-radius: 8px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none !important;
  }
  .ant-empty-description {
    color: #bccbe0 !important;
  }
}
