.bold {
  font-weight: bold;
}
.fz12 {
  font-size: 12px;
}
.fz14 {
  font-size: 14px;
}
.fz16 {
  font-size: 16px;
}
.fz18 {
  font-size: 18px;
}
.fz20 {
  font-size: 20px;
}
.fz24 {
  font-size: 24px;
}
.fz28 {
  font-size: 28px;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.absCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.xCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ylCenter {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.yrCenter {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.mt5 {
  margin-top: 5px;
}
.mr5 {
  margin-right: 5px;
}
.ml5 {
  margin-left: 5px;
}
.mb5 {
  margin-bottom: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mr10 {
  margin-right: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mt12 {
  margin-top: 12px;
}
.mr12 {
  margin-right: 12px;
}
.mb12 {
  margin-bottom: 12px;
}
.ml12 {
  margin-left: 12px;
}
.mt16 {
  margin-top: 16px;
}
.mr16 {
  margin-right: 16px;
}
.mb16 {
  margin-bottom: 16px;
}
.ml16 {
  margin-left: 16px;
}
.mt20 {
  margin-top: 20px;
}
.mr20 {
  margin-right: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.ml20 {
  margin-left: 20px;
}
.mt24 {
  margin-top: 24px;
}
.mr24 {
  margin-right: 24px;
}
.mb24 {
  margin-bottom: 24px;
}
.ml24 {
  margin-left: 24px;
}
.startStart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.startStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.startCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.centerCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.betweenCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.betweenEnd {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.endCenter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.aroundCenter {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.columnCenterStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.columnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.columnStartStretch {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.columnBetweentStart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.columnAroundStart {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.columnStartStart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.columnStartEnd {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.flex1 {
  flex: 1;
}
.br4 {
  border-radius: 4px;
}
.w50 {
  width: 50%;
}
.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.floatRight {
  float: right;
}
.link {
  cursor: pointer;
  color: @primary-color;
}
.white {
  color: @white;
}
.black {
  color: @black;
}
.error {
  color: @error;
}
.green {
  color: @green;
}
.blue {
  color: @blue;
}
.gray03 {
  color: @gray03;
}
.gray05 {
  color: @gray05;
}
.gray07 {
  color: @gray07;
}
.gray09 {
  color: @gray09;
}
.dark03 {
  color: @dark03;
}
.dark05 {
  color: @dark05;
}
.dark07 {
  color: @dark07;
}
.dark09 {
  color: @dark09;
}
.singleHidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.multiHidden {
  // -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multiHidden1 {
  .multiHidden;
  -webkit-line-clamp: 1;
}
.breakAll {
  word-break: break-all;
}
.breakWord {
  word-break: break-word;
}
.hideScrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.image {
  aspect-ratio: 1.6;
  object-fit: cover;
}
