@selbizcolor: #4bd2de;

.content-box {
  position: relative;
  padding: 16px 20px;
  border-radius: 12px;
  background-color: #1b2333;
  opacity: 0.96;
  position: relative;
  // overflow: hidden;
  .content-box-top-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 48px;
    border-radius: 12px;
    background: linear-gradient(rgba(75, 210, 222, 0.2), rgba(75, 210, 222, 0));
  }
  .content-box-left-bg {
    position: absolute;
    left: 20px;
    top: 0;
    width: 36px;
    height: 6px;
    background-image: url('../../../../assets/img/decoration_top.png');
    background-size: 100% 100%;
  }
  .content-box-right-bg {
    position: absolute;
    top: 0;
    right: 30px;
    width: 48px;
    height: 1px;
    background-color: #4bd2de;
  }
  .content-box-bg {
    position: absolute;
    left: -30px;
    top: 0px;
    width: 60px;
    height: 60px;
    background-image: url('../../../../assets/img/content_box_bg.png');
    background-size: 60px;
  }
  .content-box-icon {
    position: absolute;
    left: 0;
    top: 18px;
    width: 3px;
    height: 16px;
    background-image: url('../../../../assets/img/decoration_left.png');
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    padding-right: 18px;
  }
  .title-img {
    width: 100%;
    height: 4px;
    margin-top: 4px;
    background-image: url('../../../../assets/img/decoration_underline.png');
    background-size: 100% 100%;
  }
  .biz {
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.44);
  }
  .sel-biz {
    color: @selbizcolor;
  }
}
