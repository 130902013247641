.contentStyle {
  width: 100%;
  margin-top: 10px;
  span {
    display: block;
    white-space: nowrap;
  }
  .squareTips {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: pink;
    line-height: 8px;
    margin-right: 6px;
    flex-shrink: 0;
  }
  .contentStatus {
    font-size: 14px;
    margin-right: 10px;
  }
  .contentName {
    // margin-right: 16px;
    margin-right: 4%;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    color: white;
  }
  .contentSubwayName {
    // width: 174px;
    width: 38%;
    color: white;
    white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 隐藏溢出内容 */
    text-overflow: ellipsis;
    margin-right: 14px;
    vertical-align: middle;
  }
  .contentTime {
    font-family: Avenir;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    color: white;
    flex-shrink: 0;
  }
}
#parentContent {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(75, 210, 222, 0.4);
  position: relative;
  border-radius: 10px;
  .bannerContent {
    margin-top: 5px;
    float: left;
    width: 49%;
    position: relative;
    margin-bottom: 8px;
    .bannerContentImg {
      max-width: 100%;
      height: 135px;
      aspect-ratio: 3/2;
      object-fit: cover;
      border: 1px solid #286376;
      border-radius: 6px;
    }
    .bannerContentTime {
      margin-left: 1px;
      border-radius: 6px;
      padding-left: 10px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5));
      position: absolute;
      bottom: 0px;
      width: calc(100% - 2px);
      height: 36px;
      line-height: 36px;
      color: white;
    }
    .bannerSmall {
      width: 16px;
      height: 1px;
      background: #4bd2de;
      position: absolute;
      top: -1px;
      right: 16px;
    }
    .bannerContentText {
      color: white;
      margin-top: 5px;
      white-space: nowrap; /* 禁止换行 */
      overflow: hidden; /* 隐藏溢出内容 */
      text-overflow: ellipsis;
    }
  }
}
