@import '../../../../style/theme.less';

.subway-center-map {
  position: absolute;
  left: calc(24% + 12px);
  right: calc(24% + 12px);
  top: 0px;
  bottom: -12px;
  .map-spin {
    height: 100%;
    .ant-spin-container {
      height: 100%;
      width: 100%;
    }
    .ant-spin-blur {
      overflow: inherit;
      opacity: 1;
    }
    .ant-spin-blur::after {
      display: none;
    }
    .ant-spin-text {
      text-shadow: none !important;
    }
    .ant-spin {
      max-height: none;
    }
  }
  .full-map-spin {
    position: absolute;
    width: calc(100vw);
    height: calc(100vh);
    left: 50%;
    transform: translateX(-50%);
  }
  .map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: calc(100vw - 24px);
    transform: translateX(-50%);
    z-index: 0;
    .rotate-circle,
    .tmap-scale-control,
    a > img,
    .logo-text {
      display: none;
    }
  }
  .layer-control-container {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    .subway-alarm {
      position: relative;
      .alarm-tip {
        background-color: #cc3131;
        padding: 6px 10px;
      }
      .alarm-list {
        position: absolute;
        width: 100%;
        padding: 20px;
        border: 0.5px solid #cc3131;
        background: #424b5c;
        border-radius: 12px;
        z-index: 999;
        max-height: 450px;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
      }
      .alarm-arrow {
        width: 12px;
        height: 12px;
        transition: transform 0.3s ease;
      }
      .alarm-arrow-up {
        transform: rotate(-180deg);
      }
      .alarm-bage {
        display: inline-block;
        margin-right: 8px;
        width: 8px;
        height: 8px;
        border-radius: 2px;
      }
    }
    .layer-control-item {
      border-radius: 12px;
      background: linear-gradient(180deg, #41516c 0%, #2a3a57 51%, #27475b 100%);
      box-shadow: 0px 2px 6px 0px rgba(20, 30, 45, 0.51),
        inset 0px 1px 2px 0px rgba(255, 255, 255, 0.3);
      border: 0.5px solid #306a7e;
      padding: 12px;
      height: 50px;
      box-sizing: border-box;
    }
    .manage-desk {
      position: absolute;
      right: 0px;
      // width: 414px;
      padding: 20px;
      margin-top: 12px;
      border: 0.5px solid rgba(255, 255, 255, 0.2);
      background: #424b5c;
      border-radius: 12px;
    }
    .manage-desk-btn {
      width: 93px;
      height: 36px;
      border-radius: 8px;
      margin-right: 12px;
      background: #424b5c;
      border: 0.5px solid rgba(255, 255, 255, 0.2);
    }
    .manage-desk-btn-highlight {
      border: 0.5px solid #2965ff;
      background: rgba(41, 101, 255, 0.2);
    }
    .iconTipDesk {
      padding: 4px 10px;
      z-index: 1001;
      background: rgba(0, 37, 95, 0.8);
      border: 1px solid rgba(5, 49, 126, 1);
      box-shadow: inset 0 0 71px 0 rgba(22, 112, 247, 0.16);
      border-radius: 4px;
    }
    .legend-horizon-divider {
      position: absolute;
      top: 52px;
      left: 20px;
      right: 20px;
      height: 1px;
      background: #bccbe0;
      opacity: 0.1;
    }
    .legend-vertical-divider {
      width: 1px;
      background: #bccbe0;
      opacity: 0.1;
      margin-left: 12px;
      margin-right: 16px;
    }
    .bike-search-input-group {
      background: rgba(27, 35, 51);
      border: 0.5px solid rgba(255, 255, 255, 0.08);
      height: 49px;
      box-sizing: border-box;
      border-radius: 12px;
      overflow: hidden;
    }
    .bike-search-select {
      background-color: #313847;
      width: 120px;
      .ant-select-selector {
        height: 100% !important;
        border: 0px !important;
      }
      .ant-select-selection-item {
        line-height: 49px !important;
      }
      .ant-select-arrow {
        color: #fff;
      }
    }
    .bike-search-input {
      .ant-input {
        background: #1b2333 !important;
        opacity: 0.88;
        color: #ffffff;
        border: 0px;
        height: 49px;
      }
      .ant-input-search-button,
      .ant-input-group-addon {
        background: #1b2333;
        // opacity: 0.88;
        color: #ffffff !important;
        border: 0px;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
      }
    }
    .subway-search-select {
      .ant-select-selection-placeholder {
        color: #fff;
      }
    }
  }
  .layer-control-container-fullScreen {
    right: calc(24% + 18px);
  }
  .layer-control-container-double {
    // top: 192px;
  }
  .bottom-control {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 20;
    .map-control {
      position: fixed;
      bottom: 12px;
      right: calc(24% + 18px);
      .map-control-item {
        background: rgba(27, 35, 51, 0.88);
        border: 0.5px solid rgba(255, 255, 255, 0.08);
        box-shadow: inset 0 0 71px 0 rgba(22, 112, 247, 0.16);
        border-radius: 12px;
        padding: 14px 12px;
        .map-control-item-img-box {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
        .map-control-zoom-divide {
          width: 24px;
          height: 1px;
          margin: 8px 0px;
          opacity: 0.5;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
  .map-city-info {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    color: #151e2d;
    cursor: pointer;
    border: 1px solid #3feefd;
    background: rgba(63, 238, 253, 0.8);
    position: absolute;
    top: 0px;
    left: 0px;
    .map-city-info-name {
      font-weight: 500;
      line-height: 14px;
      font-family: PingFangSC-Medium;
    }
    .map-city-info-count {
      font-family: Avenir;
      font-size: 24px;
      line-height: 24px;
      margin-top: 8px;
    }
  }
  .bike-cluster-bubble {
    border-radius: 50%;
    // font-weight: 500;
    // padding: 20px;
    text-align: center;
    border: 2px solid #ffffff;
    // color: rgba(81, 66, 0, 1);
    cursor: pointer;
    background: #997449;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.28);
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .bike-cluster-bubble-mt {
    color: #1a1a1a;
    background: #ffd400;
  }
  .bike-cluster-bubble-hb {
    color: #fff;
    background: #42a9d4;
  }
  .bike-cluster-bubble-qj {
    color: #fff;
    background: #027d7d;
  }
  .bike-cluster-bubble-dh {
    color: #fff;
    background: #93a6f5;
  }
  .bike-cluster-bubble-qf {
    color: #fff;
    background: #b56cf5;
  }
  .digits-1 {
    // font-size: 14px;
    height: 46px;
    width: 46px;
  }

  .digits-2 {
    // font-size: 16px;
    height: 50px;
    width: 50px;
  }

  .digits-3 {
    // font-size: 18px;
    height: 60px;
    width: 60px;
  }

  .digits-4 {
    // font-size: 18px;
    height: 80px;
    width: 80px;
  }

  .digits-5 {
    // font-size: 20px;
    height: 100px;
    width: 100px;
  }

  .digits-6 {
    // font-size: 24px;
    height: 120px;
    width: 120px;
  }
}
.stop-alarm-win {
  padding: 16px 20px 4px;
  // opacity: 0.9;
  background: #424b5c;
  // border: 1px solid rgba(162, 233, 255, 0.2);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  // box-shadow: 0 2px 6px 0 rgba(0, 14, 255, 0.19);
  border-radius: 8px;
  .close {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 5px;
    top: 3px;
    cursor: pointer;
  }
  .triangle {
    width: 20px;
    height: 20px;
    opacity: 0.9;
    background-color: #424b5c;
    transform: rotate(45deg);
    position: absolute;
    bottom: -2px;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: -1;
  }
  .label {
    display: inline-block;
    color: #bccbe0;
    width: 140px;
  }
}
